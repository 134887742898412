<template>
  <span v-if="Object.keys(detect).length != 0">
    <v-chip
      small
      class="pa-2"
      color="red"
      text-color="white"
      v-if="(detect.boar != 0) & (detect.boar != undefined)"
    >
      イノシシ
      <v-avatar small right class="red darken-4">
        {{ detect.boar }}
      </v-avatar>
    </v-chip>
    <v-chip
      small
      class="pa-2"
      color="blue"
      text-color="white"
      v-if="
        (detect.macaca_fuscata != 0) & (detect.macaca_fuscata != undefined) ||
          (detect.macaca_fuscata_cage != 0) &
            (detect.macaca_fuscata_cage != undefined)
      "
    >
      ニホンザル
      <v-avatar small right class="blue darken-4">
        {{
          detect.macaca_fuscata != undefined &&
          detect.macaca_fuscata_cage != undefined
            ? detect.macaca_fuscata + detect.macaca_fuscata_cage
            : detect.macaca_fuscata != undefined
            ? detect.macaca_fuscata
            : detect.macaca_fuscata_cage
        }}
      </v-avatar>
    </v-chip>
    <v-chip
      small
      class="pa-2"
      color="green"
      text-color="white"
      v-if="(detect.deer != 0) & (detect.deer != undefined)"
    >
      シカ
      <v-avatar small right class="green darken-4">
        {{ detect.deer }}
      </v-avatar>
    </v-chip>
    <v-chip
      small
      class="pa-2"
      color="#e69138"
      text-color="white"
      v-if="(detect.racoon_dog != 0) & (detect.racoon_dog != undefined)"
    >
      タヌキ
      <v-avatar small right color="#cc8131">
        {{ detect.racoon_dog }}
      </v-avatar>
    </v-chip>
    <v-chip
      small
      class="pa-2"
      color="#FF00FF"
      text-color="white"
      v-if="
        (detect.bear != 0) & (detect.bear != undefined) ||
          (detect.bear_cage != 0) & (detect.bear_cage != undefined)
      "
    >
      クマ
      <v-avatar small right color="#990099">
        {{
          detect.bear != undefined && detect.bear_cage != undefined
            ? detect.bear + detect.bear_cage
            : detect.bear != undefined
            ? detect.bear
            : detect.bear_cage
        }}
      </v-avatar>
    </v-chip>
    <v-chip
      small
      class="pa-2"
      color="#7B7D7D"
      text-color="white"
      v-if="
        (detect.human_or_vehicle != 0) & (detect.human_or_vehicle != undefined)
      "
    >
      人・車両
    </v-chip>
  </span>
</template>

<script>
export default {
  name: "detect_chip",
  props: {
    detect: {
      type: Object,
      default() {
        return {};
      }
    }
  }
};
</script>

<style></style>
